import {useLocation} from "react-router-dom";

import "./Article.css"
import {aiContent} from "./articles/Ai";
import {FormattedMessage} from "react-intl";
import {DeleteHistory} from "./articles/DeleteHistory";
import {SearchByKeyword} from "./articles/SearchByKeyword";
import {AdExposure} from "./articles/AdExposure";
import {NeedVerify} from "./articles/NeedVerify";
import {PowerProduct} from "./articles/PowerProduct";
import {Tech} from "./articles/Tech";
import {AnnualReport} from "./articles/AnnualReport";

//            {
//                 id: "ai",
//                 imgUrl: bot,
//                 title: "Use OpenAI to help advertisers publish tasks",
//                 date: "2024.03.18",
//                 desc: "Utilize the cutting-edge capabilities of OpenAI to streamline and enhance your advertising strategies. Our platform enables advertisers to effortlessly publish and manage tasks, reaching a wider audience with precision and effectiveness. Embrace the power of AI and transform your advertising efforts into impactful results.",
//                 style: {
//                     flexGrow: "2",
//                 },
//                 imgStyle: {
//                     height: "300px",
//                 }
//             },
/**
 * this is the article template to fill any articles
 * @constructor
 */
export function Article() {
    // use location to find the corresponding article content
    const location = useLocation();
    const article = location.state;

    return <div className={"article_area"}>
        <div className={"article_container"}>
            <div style={{
                padding: "10px 0",
                marginBottom: "40px",
                borderBottom: "1px solid #D8D9Dc",
            }}>
                <h1 className={"article_title"} style={{
                    fontSize: "44px",
                    lineHeight: "66px",
                    paddingBottom: "7px",
                    fontWeight: "500",
                    fontVariantLigatures: "no-common-ligatures",
                }}><FormattedMessage id={article.title}/></h1>
                <div style={{lineHeight: "25px", color: "#919797", fontSize: "14px"}}>
                    <FormattedMessage id={article.date}/></div>
            </div>
            <div className={"article_content"} style={{
                paddingBottom: "110px",
                width: "100%",
                color: "#5F6464"
            }}>
                {getArticleContent(article)}
            </div>
        </div>
    </div>
}

function getArticleContent(article) {
    switch (article.id) {
        case "ai":
            return aiContent()
        case "delete":
            return DeleteHistory()
        case "keyword":
            return SearchByKeyword()
        case "exposure":
            return AdExposure()
        case "sms":
            return NeedVerify()
        case "power":
            return PowerProduct()
        case "tech":
            return Tech()
        case "fund":
            return AnnualReport()
        default:
            return getDefaultContent()
    }
}


function getDefaultContent() {
    return <div>
        <FormattedMessage id={'article.none'}></FormattedMessage>
    </div>
}
