import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import {Content} from "./components/Content/Content";
import {Article} from "./components/Articles/Article";
import {About} from "./components/Subtitles/About";
import {Statement} from "./components/Statements/Statement";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App></App>,
        errorElement: <ErrorPage></ErrorPage>, // default error page like 404
        children: [
            {
                index: true,
                element: <Content></Content>
            },
            {
                path: "about",
                element: <About></About>
            },
            {
                path: "article/:articleId",
                element: <Article></Article>
            },
            {
                path: "statement/:statementType",
                element: <Statement></Statement>
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}></RouterProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
