import React from 'react';

export const AnnualReport = () => (
    <div>
        <h1>年度财报</h1>
        <p>
            随着财年的结束，公司迎来了又一个年度财报的发布时刻。年度财报不仅是对公司过去一年财务状况的总结，也是展望未来发展的重要依据。以下是公司本年度财报的详细分析。
        </p>
        <h2>财务业绩</h2>
        <p>
            在过去的一年中，公司实现了稳健的财务增长。总收入达到50亿元人民币，同比增长15%。其中，主营业务收入为45亿元，占总收入的90%，其他业务收入为5亿元，占总收入的10%。公司净利润为10亿元，同比增长20%，净利润率为20%，高于行业平均水平。
        </p>
        <h2>业务部门表现</h2>
        <p>
            公司的主要业务部门在本年度表现出色。技术服务部门收入为20亿元，同比增长25%，成为公司增长最快的部门。产品销售部门收入为15亿元，同比增长10%。另外，新兴业务部门收入为5亿元，同比持平，主要受市场竞争加剧的影响。
        </p>
        <h2>成本控制</h2>
        <p>
            在成本控制方面，公司采取了一系列有效措施。全年总成本为35亿元，同比增长10%，低于收入增长率。公司通过优化供应链管理、提升生产效率和严格控制运营成本，实现了成本的有效控制。尤其是研发投入达到了5亿元，占总收入的10%，为公司的长期发展奠定了坚实基础。
        </p>
        <h2>资产负债情况</h2>
        <p>
            截至本财年末，公司总资产为100亿元，同比增长12%。其中，流动资产为40亿元，占总资产的40%；非流动资产为60亿元，占总资产的60%。公司总负债为50亿元，同比增长8%，资产负债率为50%，较上一年度有所下降，表明公司的财务结构更加稳健。
        </p>
        <h2>现金流状况</h2>
        <p>
            公司的现金流状况保持健康。全年经营活动产生的现金流量净额为15亿元，同比增长18%。投资活动产生的现金流量净额为-5亿元，主要用于技术研发和固定资产投资。筹资活动产生的现金流量净额为5亿元，主要来自新增贷款和股权融资。
        </p>
        <h2>股东权益</h2>
        <p>
            公司股东权益为50亿元，同比增长15%。本年度公司向股东分红2亿元，每股派息1元，股息率为4%。此外，公司还实施了股票回购计划，回购股票数量占总股本的5%，提升了每股收益。
        </p>
        <h2>未来展望</h2>
        <p>
            展望未来，公司将继续致力于技术创新和市场拓展。计划在下一个财年增加对人工智能和大数据领域的投资，进一步提升公司的核心竞争力。同时，公司将加强国际市场的布局，预计海外收入将实现20%的增长。此外，公司将继续优化内部管理，提高运营效率，确保可持续发展。
        </p>
        <h2>风险管理</h2>
        <p>
            公司在风险管理方面也取得了显著成效。通过建立完善的风险控制体系和严格的内控机制，公司有效防范了市场风险、财务风险和运营风险。此外，公司还加强了对外部环境变化的应对能力，确保在复杂多变的市场环境中保持稳定发展。
        </p>
        <h2>社会责任</h2>
        <p>
            公司始终坚持履行社会责任。在环保方面，公司加大了环保设施的投入，全年减少碳排放10万吨。在社会公益方面，公司捐赠了5000万元用于教育和医疗事业。此外，公司还积极参与社区建设和志愿服务，获得了多项社会荣誉。
        </p>
        <p>
            总的来说，公司在过去一年中取得了显著的成绩。通过持续的创新和高效的管理，公司不仅实现了财务业绩的稳步增长，还为未来的发展打下了坚实的基础。我们相信，在全体员工的共同努力下，公司将继续保持良好的发展势头，实现更大的成功。
        </p>
    </div>
);
