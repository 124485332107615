
export function About() {
    return (
        <div className={"article_area"}>
            <div className={"article_container"}>
                <div className={"article_content"} style={{
                    paddingBottom: "110px",
                    width: "100%",
                    color: "#5F6464"
                }}>
                    <div>
                        <h1>公司介绍：</h1>
                        <p>
                            <strong>公司名称：</strong> 新柚信息科技有限公司<br/>
                            <strong>注册地点：</strong> 中国河南
                        </p>
                        <h2>关于我们：</h2>
                        <p>
                            新柚信息科技有限公司致力于打造一流的广告服务平台，为商家和博主提供高效便捷的连接渠道。我们的平台旨在促进商家与博主之间的合作，让他们能够更加有效地推广产品和服务，实现双赢局面。
                        </p>
                        <h2>产品特点：</h2>
                        <p>
                            我们的主要产品是一个广告服务平台，通过该平台，商家可以发布广告任务，包括但不限于产品推广、品牌曝光等。注册在我们平台的博主可以选择接受这些任务，并按照商定的条件完成任务，从而获得相应的报酬。我们致力于为商家和博主提供安全、可靠、高效的服务，为他们之间的合作提供便利。
                        </p>
                        <h2>使命与愿景：</h2>
                        <p>
                            我们的使命是成为行业领先的广告服务平台，为商家和博主搭建一个互利共赢的合作平台，推动广告行业的发展。我们的愿景是通过创新和努力，成为客户信赖的首选平台，为他们提供最优质的服务和体验。
                        </p>
                        <h2>联系方式：</h2>
                        <div>
                            如果您对我们的产品和服务有任何疑问或建议，欢迎随时联系我们。您可以通过以下方式与我们取得联系：<br/>
                            <ul>
                                <li>
                                    电话：+86 17737352268
                                </li>
                                <li>
                                    邮箱：bosstostar@gmail.com
                                </li>
                            </ul>
                        </div>
                        <p>
                            <strong>谢谢您对新柚信息科技有限公司的关注和支持！</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    );
}
