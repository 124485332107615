export const zh_CN = {
    // links
    "About": "关于我们",
    "About Us": "公司简介",
    "Others": "其他信息",
    "Milestones": "发展历程",
    "Our Culture": "企业文化",
    "Our Office": "办公环境",
    "Business": "业务",
    "Consumers": "面向消费者",
    "Enterprises": "面向企业",
    "Employees": "员工",
    "Tencent Academy": "新柚学院",
    "Employee Activities": "员工活动",
    "Investors": "投资者",
    "Environment, Social and Governance": "环境、社会和治理",
    "Advertiser": "广告服务",
    "Platform": "广告平台",
    "Documents": "相关文档",
    // aiContent
    "Introduction.title": "介绍",
    "Introduction.content": "在当今数字时代，广告行业不断寻求创新技术以提高效率和效果。OpenAI 以其尖端的人工智能技术，提供了改变游戏规则的解决方案，简化了广告任务发布流程。本文探讨了 OpenAI 技术在广告平台中的整合，为广告商提供了如何利用 AI 最大化其覆盖面并优化营销策略的全面指南。",
    "RoleOfAI.title": "AI 在现代广告中的作用",
    "RoleOfAI.content": "人工智能已成为数字广告的基石，提供了自动化和优化大规模跨平台广告活动的工具。AI 技术可以分析大量数据以预测用户行为，个性化广告，并比以往更有效地管理广告投放。OpenAI 的先进算法可以处理复杂的数据集，识别出人类分析师可能错过的趋势和见解，从而增强决策过程。",
    "StreamliningTask.title": "使用 OpenAI 简化任务发布",
    "StreamliningTask.content": "将 OpenAI 整合到广告平台中的一个关键好处是任务发布的自动化。广告商常常面临针对合适受众并根据不断变化的市场动态调整策略的挑战。OpenAI 的 AI 模型可以基于实时数据分析自动生成和调整广告内容，确保广告不仅定位精准，而且成本高效。",
    "Features.title": "OpenAI 增强广告平台的功能",
    "Features.automatedContentCreation": "自动内容创建：OpenAI 的 GPT（生成预训练变换器）模型可以创建引人入胜且相关的广告文案，减少手动内容创建的需要。这种自动化加快了广告活动的设置过程，使广告商能够快速响应市场变化。",
    "Features.predictiveAnalytics": "预测分析：AI 驱动的分析工具帮助根据历史数据和当前趋势预测广告活动表现。这种预测能力使广告商能够更有效地分配预算并调整广告活动以获得最大效果。",
    "Features.audienceSegmentation": "受众细分：AI 算法分析用户数据，根据行为、人口统计和偏好对受众进行细分。这种细分有助于为特定群体量身定制广告，提高广告活动的相关性和有效性。",
    "Features.realTimeAdjustments": "实时调整：AI 模型从传入数据中不断学习，允许对实时广告活动进行调整。此功能确保广告在其生命周期内始终保持最佳状态，适应用户参与和反馈。",
    "CaseStudies.title": "案例研究",
    "CaseStudies.content": "一些案例研究突显了 OpenAI 在广告中的成功整合。例如，一家大型零售品牌使用 AI 驱动的平台将其覆盖面增加了一倍，同时将广告成本降低了 20%。通过 AI，该品牌能够动态调整其广告投放和内容，从而提高参与率和投资回报率。",
    "Challenges.title": "挑战和考虑",
    "Challenges.content": "虽然 AI 提供了许多优势，但也有一些挑战需要考虑，例如数据隐私问题和对 AI 操作透明度的需求。广告商必须确保其使用 AI 符合数据保护法规和道德标准。",
    "FutureProspects.title": "未来前景",
    "FutureProspects.content": "随着机器学习模型和 AI 功能的不断进步，AI 广告的未来前景广阔。随着 AI 变得更加复杂，它将在制定精准和有影响力的广告策略方面发挥更重要的作用。",
    "Conclusion.title": "结论",
    "Conclusion.content": "利用 OpenAI 进行广告任务提供了巨大的好处，从自动化繁琐的任务到深入了解广告活动表现。随着行业的发展，AI 技术将成为广告商在快速变化的数字环境中保持竞争力的基本工具。",

    // main page
    // locales/zh_CN.js
    "ai.title": "利用 OpenAI 帮助广告商发布任务",
    "ai.date": "2024.03.18",
    "ai.desc": "利用 OpenAI 的尖端能力来简化和增强您的广告策略。我们的平台使广告商能够轻松发布和管理任务，以精准和有效的方式接触更广泛的受众。拥抱 AI 的力量，将您的广告工作转变为有影响力的结果。",


    "delete.title": "添加删除历史任务的功能",
    "delete.date": "2024.01.29",
    "delete.desc": "我们的最新功能允许轻松删除历史任务。通过删除已完成或过时的条目简化您的任务列表，确保您的重点始终放在真正重要的任务上。",

    "keyword.title": "按关键字搜索任务",
    "keyword.date": "2024.01.18",
    "keyword.desc": "利用我们的高级搜索功能，您可以通过关键字快速定位任务。无需再翻阅大量数据；只需输入相关术语，我们的智能搜索将为您呈现匹配查询的任务列表。",

    "exposure.title": "增加广告曝光",
    "exposure.date": "2024.01.03",
    "exposure.desc": "通过我们设计的战略性广告解决方案，最大限度地提高品牌的可见性。与更广泛的受众联系，留下深刻印象。",

    "sms.title": "订单完成确认需要短信验证",
    "sms.date": "2023.12.21",
    "sms.desc": "为了增强交易安全性，我们平台的最新更新引入了订单完成时的强制短信验证。客户将通过短信收到唯一的代码以确认他们的交易，从而增强了防欺诈保护并通过几个简单步骤确认购买的合法性。体验我们的两步短信验证过程，确保每个订单都由您直接确认。安全且简便，这是每笔交易的额外信任保障。",

    "article.ai.title": "使用 OpenAI 帮助广告商发布任务",
    "article.ai.date": "2024.03.18",
    "article.ai.desc": "利用 OpenAI 的尖端能力来简化和增强您的广告策略。我们的平台使广告商能够轻松发布和管理任务，以精准和有效的方式接触更广泛的受众。拥抱 AI 的力量，将您的广告工作转变为有影响力的结果。",

    "carousel.title1": "轻松让您的产品更强大",
    "carousel.date1": "2024.03.18",
    "carousel.desc1": "毫不费力地释放您的产品的全部潜力。我们的直观工具和功能使您能够以最小的努力提升性能和用户体验。",

    "carousel.title2": "2024 年十大技术趋势",
    "carousel.date2": "2024.01.18",
    "carousel.desc2": "新柚研究院与领先科学家和其他专家咨询，编制了我们的年度技术趋势前十名单。",

    "carousel.title3": "新柚宣布 2023 年年度和第四季度财报",
    "carousel.date3": "2024.04.18",
    "carousel.desc3": " ",

    // footer
    "follow.us": "关注我们",
    "Join Us": "加入我们",
    "Contact Us": "联系我们",
    "Legal Information": "法律信息",
    "XinYou Careers": "新柚职业",
    "Campus Recruitment": "校园招聘",
    "Global Recruitment": "全球招聘",
    "Customer Services": "客户服务",
    "Partnership": "合作伙伴关系",
    "Procurement": "采购",
    "Compliance": "合规",
    "Media & Investors": "媒体与投资者",
    "Service Agreement": "服务协议",
    "Privacy Policy": "隐私政策",
    "Intellectual Property Rights": "知识产权",
    "copyright": "版权所有 © 2024 新柚信息科技有限公司",
    "company-name": "新柚信息科技",
    "website-record": "豫ICP备2023034876号-2",


    // others
    "What's New": "最新动态",
    "article.none": "抱歉，未找到此篇文章。",

    // error
    "error.page.title": "糟糕！",
    "error.page.message": "抱歉，资源正在准备中。"
};
