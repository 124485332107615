import {FormattedMessage, IntlProvider} from 'react-intl';
import {useState} from 'react';
import {en_US} from '../configs/en_US';
import {zh_CN} from '../configs/zh_CN';
import {useRouteError} from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    const [locale] = useState(() => {
        const storedLocale = localStorage.getItem('locale');
        return storedLocale || "en"; // 如果 localStorage 中存在语言偏好，则使用存储的语言偏好，否则使用默认的英文
    });


    // 根据 locale 的值选择相应的语言配置
    const messages = locale === 'en' ? en_US : zh_CN;

    return (
        <IntlProvider locale={locale} messages={messages}>
            <div id="error-page" className={"container"} style={{
                display: "flex",
                width: "100%",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}>
                <h1><FormattedMessage id="error.page.title" defaultMessage="Oops!"/></h1>
                <p><FormattedMessage id="error.page.message" defaultMessage="Sorry, an unexpected error has occurred."/>
                </p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
            </div>
        </IntlProvider>
    );
}
