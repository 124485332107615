import {Link, useLocation, useNavigate} from "react-router-dom";
import "./Header.css"
import {useContext, useEffect, useState} from "react";
import _ from "lodash";
import {Collapse} from "react-bootstrap";
import {FormattedMessage, IntlContext} from "react-intl";

function getLinkArr() {
    let links = [{
        title: "About", to: "/about", subLinks: [{
            title: "About Us", to: "/about"
        }, {
            title: "Others", to: "/other"
        }, {
            title: "Milestones", to: "/milestone"
        }, {
            title: "Our Culture", to: "/culture"
        }, {
            title: "Our Office", to: "/office"
        }]
    }, {
        title: "Business", to: "/consumers", subLinks: [{
            title: "Consumers", to: "/consumers"
        }, {
            title: "Enterprises", to: "/enterprises"
        }]
    }, {
        title: "Employees", to: "/academy", subLinks: [{
            title: "Tencent Academy", to: "/academy"
        }, {
            title: "Employee Activities", to: "/activity"
        },
        ]
    }, {
        title: "Investors", to: "/esg", subLinks: [{
            title: "Environment, Social and Governance", to: "/esg"
        }, {
            title: "Others", to: "/inother"
        }]
    }, {
        title: "Advertiser", to: "https://boss.bosstostar.com", subLinks: [{
            title: "Platform", to: "https://boss.bosstostar.com"
        }, {
            title: "Documents", to: "/doc"
        }]
    }]
    return links
}

function scrollEvent(force) {
    let lastScrollTop = 0;

    const headerArea = document.getElementById('header_area');

    headerArea.style.transform = 'translateY(0)';

    const onScroll = _.debounce(() => {
        if (!headerArea) return;

        const currentScroll = document.documentElement.scrollTop;
        if (currentScroll > 50) {
            headerArea.style.backgroundColor = "rgba(33, 40, 48,0.8)"
        } else {
            headerArea.style.backgroundColor = "rgba(255, 255, 255, 0)";
        }

        if (currentScroll < 100) {
            return;
        }
        if (currentScroll > lastScrollTop && currentScroll - lastScrollTop > 0.1) {
            // 向下滑超过10px，通过改变transform实现向上滑出的效果
            headerArea.style.transform = 'translateY(-200%)';
        } else if (lastScrollTop > currentScroll && lastScrollTop - currentScroll > 0.1) {
            // 向上滑超过10px，通过重置transform实现向下滑入的效果
            headerArea.style.transform = 'translateY(0)';
        }


        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    }, 0); // 使用防抖来优化性能

    window.addEventListener('scroll', onScroll);
    return onScroll;
}

function hoverMenuItem() {
    const menus = document.querySelectorAll(".menu_item");
    menus.forEach((e) => {
        e.addEventListener("mouseenter", () => {
            e.classList.add("menu_item_show")
            const subListEl = e.querySelector(".sub_list");
            subListEl.classList.add("sub_list_show")
        })

        e.addEventListener("mouseleave", () => {
            e.classList.remove("menu_item_show")
            const subListEl = e.querySelector(".sub_list");
            subListEl.classList.remove("sub_list_show")
        })
    })
}


function MobileLink(mainLink, i) {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen);

    const subLinks = mainLink.subLinks.map((subLink, i) => {
        return (<li key={i}>
            <Link to={subLink.to}>{subLink.title}</Link>
        </li>)
    })

    return (<li className={"menu_item_mobile"} key={i}>
        <div className={"one-link"}>
            <Link to={mainLink.to} className={"default_link"}>{mainLink.title}</Link>
            <div style={{flexGrow: "1"}} onClick={toggle}></div>
            <img style={{fill: "white"}}
                 src={"https://upload-1306824275.cos.ap-guangzhou.myqcloud.com/1720854085_arrow.svg"}
                 alt="load failed" onClick={toggle} className={isOpen ? "open_collapse" : undefined}/>
        </div>
        <Collapse in={isOpen}>
            <ul className={"sub_list"}>
                {subLinks}
            </ul>
        </Collapse>

    </li>)
}

export function MobileHeader({setLocale}) {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        // 监听下滑和上滑
        const onScroll = scrollEvent();

        // 定义点击事件的处理函数
        const handleClickOutside = (event) => {
            const myElement = document.getElementById('header_bar_mobile');
            if (myElement.classList.contains("open")) {
                if (myElement && !myElement.contains(event.target)) {
                    handleClickSidebarIcon(true, event)
                }
            }
        };

        // 添加事件监听器
        document.addEventListener('click', handleClickOutside);

        // 清理函数，组件卸载时执行
        return () => {
            window.removeEventListener('scroll', onScroll)
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    function handleClickLang(lang) {
        if (lang === "zh") {
            setLocale('zh')
        } else if (lang === "en") {
            setLocale('en')
        }
    }

    function handleClickSidebarIcon(close, e) {
        if (e) {
            // 禁止冒泡
            e.stopPropagation()
        }
        let headerBar = document.querySelector("#header_bar_mobile");
        headerBar.classList.toggle("open")
    }

    // get links
    const headerLinks = getLinkArr().map((mainLink, i) => {
        return MobileLink(mainLink, i);
    })

    const handleClickB2sTitle = () => {
        navigate('/')
    }

    const intlShape = useContext(IntlContext)

    return (<>
        <div id={"header_area"} style={{
            display: "flex", alignItems: "center",
        }} className={location.pathname !== "/" ? "header_with_back" : ""}>
            <label className={"side-bar-icon"} style={{
                fill: "white"
            }} onClick={(e) => {
                handleClickSidebarIcon(false, e)
            }}> </label>
            <h1 style={{
                fontSize: "x-large",
                fontFamily: "SYfont",
                margin: 0,
                fontWeight: 600,
                color: "white",
                flexGrow: "1",
                textAlign: "center",
            }} id={"b2s_title"} onClick={handleClickB2sTitle}>Boss To Star</h1>
        </div>
        {<div id={"header_bar_mobile"} style={{
            position: "fixed",
            top: "0",
            height: "100vh",
            zIndex: "3",
            width: "220px",
            backgroundColor: "black",
            display: "flex",
            flexDirection: "column",
        }}>
            <div className={"inner-header"} style={{
                height: "100px"
            }}>
                <label
                    style={{
                        background: `url(https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/x.svg) no-repeat 0 0`,
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        position: "relative",
                        marginLeft: "18px",
                        marginTop: "20px"
                    }} onClick={(e) => handleClickSidebarIcon(true, e)}></label>
            </div>

            <div id={"header_btns"} style={{
                display: "flex", flexDirection: "column", justifyContent: "flex-start", paddingLeft: "40px", gap: "30px"
            }}>
                {headerLinks}
            </div>
            <div className={"lang_area_mobile"} style={{
                color: "white", display: "flex", gap: "5px", padding: "20px",
                marginTop: "20px"
            }}>
                <div style={{
                    flexGrow: "1",
                }}></div>
                <span className={`lang lang_ch ${intlShape.locale === "zh" ? "current" : ""}`}
                      lang="zh-cn"
                      onClick={() => handleClickLang("zh")}>简</span>
                <i className="s_line">|</i>
                <span className={`lang lang_ch ${intlShape.locale === "en" ? "current" : ""}`}
                      lang="en-us"
                      onClick={() => handleClickLang("en")}>EN</span>
            </div>
        </div>}
    </>)
}


export function Header({setLocale}) {
    const location = useLocation();
    const navigateFunction = useNavigate();

    useEffect(() => {
        // 监听下滑和上滑
        const onScroll = scrollEvent();


        // 监听hover menu的事件
        hoverMenuItem()


        // 清理函数，组件卸载时执行
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    function handleClickLang(lang) {
        if (lang === "zh") {
            setLocale("zh")
        } else if (lang === "en") {
            setLocale("en")
        }
    }

    // get links
    const headerLinks = getLinkArr().map((mainLink, i) => {
        const subLinks = mainLink.subLinks.map((subLink, i) => {
            return (<li key={i}>
                <Link to={subLink.to}>
                    <FormattedMessage id={subLink.title} defaultMessage={subLink.title}>
                    </FormattedMessage>
                </Link>
            </li>)
        })

        return (
            <li className={"menu_item"} key={i}>
                <Link to={mainLink.to} className={"default_link"}>
                    <FormattedMessage id={mainLink.title}>
                    </FormattedMessage>
                </Link>

                <ul className={"sub_list"}>
                    {subLinks}
                </ul>
            </li>)
    })

    const handleClickB2sTitle = () => {
        navigateFunction('/')
    }

    const intlShape = useContext(IntlContext)

    return (<div id={"header_area"} className={location.pathname !== "/" ? "header_with_back" : ""}>
        <div id={"header_bar"}>
            <h1 id={"b2s_title"} style={{
                fontSize: "xx-large", fontFamily: "SYfont", margin: 0, fontWeight: 700
            }} onClick={handleClickB2sTitle}>Boss To Star</h1>
            <div id={"header_btns"}>
                {headerLinks}
            </div>
            <div className={"lang_area"}>
                <span className={`lang lang_ch ${intlShape.locale === "zh" ? "current" : ""}`}
                      onClick={() => handleClickLang("zh")}>简</span>
                <i className="s_line">|</i>
                <span className={`lang lang_ch ${intlShape.locale === "en" ? "current" : ""}`}
                      onClick={() => handleClickLang("en")}>EN</span>
            </div>
        </div>
    </div>)
}

