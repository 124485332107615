import "./About.css"
import {Society} from "./Society";
import {Link} from "react-router-dom";
import {useState} from "react";
import {Collapse} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

const infoItems = [{
    title: "Join Us",
    links: [{path: "/careers", label: "XinYou Careers"}, {
        path: "/campus-recruitment",
        label: "Campus Recruitment"
    }, {path: "/global-recruitment", label: "Global Recruitment"}],
}, {
    title: "Contact Us",
    links: [{path: "/customer-services", label: "Customer Services"}, {
        path: "/partnership",
        label: "Partnership"
    }, {path: "/procurement", label: "Procurement"}, {
        path: "/compliance",
        label: "Compliance"
    }, {path: "/media-investors", label: "Media & Investors"}]
}, {
    title: "Legal Information",
    links: [{path: "/service-agreement", label: "Service Agreement"}, {
        path: "/privacy-policy",
        label: "Privacy Policy"
    }, {path: "/intellectual-property-rights", label: "Intellectual Property Rights"}]
}];


export function Footer() {
    const items = infoItems.map((item, index) => (<div className="info-item" key={index}>
        <h5 className="item-title">
            <FormattedMessage id={item.title}/>
        </h5>
        <div className="item-options">
            {item.links.map((link, linkIndex) => (<Link to={link.path} key={linkIndex}>
                <FormattedMessage id={link.label}/></Link>))}
        </div>
    </div>))
    return (<div className={"bottom-area"}>
            <div className={"bottom-container"}>
                <div className={"follow-us-area"}>
                    <h5 className={"m-0"}><FormattedMessage id={"follow.us"}/></h5>
                    <div className={"society-icons"}>
                        <Society></Society>
                    </div>
                </div>
                <div className={"company-information"}>
                    <div className={"info-area"}>
                        <div className={"info-items"}>
                            {items}
                        </div>
                    </div>
                    <div className={"company-logo"}>
                        {/*<div>Boss To Star</div>*/}
                        <img src="https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/logo-black.svg"
                             alt={"load failed"} style={{
                            width: "80%"
                        }}/>
                    </div>
                </div>
                <div className={"record-area"}>
                    <div className={"row"}>
                        <Link className={"col text-center"}>
                            <FormattedMessage id={"copyright"}/>
                        </Link>
                        <div className={"col text-center"}>
                            <FormattedMessage id={"company-name"}/></div>
                        <a className={"col text-center"} href="https://beian.miit.gov.cn/" rel="noopener noreferrer"
                           target="_blank"><FormattedMessage id={"website-record"}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

function InfoItem({
                      item
                  }) {
    const [isOpen, setIsOpen] = useState(false);  // 初始状态为关闭
    const toggle = () => setIsOpen(!isOpen);

    return (<div className="info-item mobile-info-item">
        <div className="item-title" onClick={toggle}>
            <div><FormattedMessage id={item.title}/></div>
            <img src={"https://upload-1306824275.cos.ap-guangzhou.myqcloud.com/1720854085_arrow.svg"}
                 alt={"load failed"} className={isOpen ? "open_collapse" : ""}/>
        </div>
        <Collapse in={isOpen}>
            <div className="item-options">
                {item.links.map((link, index) => (<Link to={link.path} key={index}>
                    <FormattedMessage id={link.label}/>
                </Link>))}
            </div>
        </Collapse>
    </div>);
}

export function MobileFooter() {
    const items = infoItems.map((item, index) => (<div key={index}>
        <InfoItem item={item}></InfoItem>
    </div>))
    return (<div className={"bottom-area"}>
        <div className={"bottom-container"}>
            <div className={"follow-us-area"}>
                <h5 className={"m-0"}><FormattedMessage id={"follow.us"}/></h5>
                <div className={"society-icons"}>
                    <Society></Society>
                </div>
            </div>
            <div className={"company-information"}>
                <div className={"info-area"}>
                    {items}
                </div>

                <div className={"company-logo"}>
                    <div>Boss To Star</div>
                </div>
            </div>
            <div className={"record-area"}>
                <div className={"row"}>
                    <Link className={"col-5"}>
                        <FormattedMessage id={"copyright"}/>
                    </Link>
                    <div className={"col text-center"}>
                        <FormattedMessage id={"company-name"}/></div>
                    <a className={"col"} href="https://beian.miit.gov.cn/"
                       rel="noopener noreferrer" target="_blank"><FormattedMessage id={"website-record"}/>
                    </a>
                </div>
            </div>
        </div>
    </div>)
}
