import { useParams} from "react-router-dom";
import {FormattedMessage} from "react-intl";

const statements = {
    "payment": {
        title: "支付协议与免责声明", date: "2024.07.01", innerHtml: (<div>
            <h2>支付协议及免责声明</h2>

            <h3>1. 支付协议</h3>

            <h4>1.1 服务内容</h4>
            <ul>
                <li>广告任务平台（以下简称“平台”）为广告主（以下简称“用户”）提供广告发布和任务管理服务。用户在平台上发布广告任务，并支付相应的费用。</li>
            </ul>

            <h4>1.2 支付方式</h4>
            <ul>
                <li>用户可以通过微信支付进行支付。支付时，用户需确保支付信息的准确性和有效性。</li>
            </ul>

            <h4>1.3 支付流程</h4>
            <ul>
                <li>用户在平台发布广告任务后，系统将生成对应的支付订单。</li>
                <li>用户确认支付订单后，通过平台提供的支付方式完成支付。</li>
                <li>支付完成后，系统将自动更新订单状态，并开始处理广告任务。</li>
            </ul>

            <h4>1.4 支付安全</h4>
            <ul>
                <li>平台使用安全的支付接口，保障用户支付过程中的信息安全。</li>
                <li>用户需妥善保管支付凭证和账户信息，如发现异常，请及时联系平台客服。</li>
            </ul>

            <h4>1.5 退款政策</h4>
            <ul>
                <li>如因平台原因导致广告任务无法执行，用户可申请退款。具体退款流程参见平台退款政策。</li>
                <li>退款申请需在支付完成后7个工作日内提交，平台将在收到申请后5个工作日内处理。</li>
            </ul>

            <h3>2. 免责声明</h3>

            <h4>2.1 平台责任</h4>
            <ul>
                <li>平台仅为用户提供广告任务发布和管理的技术服务，不对广告内容的合法性、真实性、准确性负责。</li>
                <li>平台有权对用户发布的广告内容进行审核，如发现违法或违规内容，有权暂停或终止服务。</li>
            </ul>

            <h4>2.2 用户责任</h4>
            <ul>
                <li>用户需确保所发布广告内容的合法性，不得包含虚假、误导、侵犯他人权益的内容。</li>
                <li>用户需自行承担因广告内容引起的法律责任和纠纷。</li>
            </ul>

            <h4>2.3 服务中断</h4>
            <ul>
                <li>平台将在服务中断或停止前提前通知用户，但因不可抗力、系统维护或其他不可控制因素导致的服务中断，平台不承担责任。</li>
            </ul>

            <h4>2.4 费用风险</h4>
            <ul>
                <li>用户在平台上的支付行为是自愿的，支付费用一旦成功，非因平台原因不得要求退款。</li>
                <li>用户需承担因支付行为产生的所有费用和风险，包括但不限于支付过程中产生的手续费、汇率差异等。</li>
            </ul>

            <h4>2.5 修改与终止</h4>
            <ul>
                <li>平台有权根据实际情况修改支付协议及免责声明，修改后的协议将在平台上公示，用户需及时关注。</li>
                <li>用户不同意修改内容的，有权停止使用平台服务；继续使用的，视为同意修改后的协议。</li>
            </ul>
        </div>)
    }
}

function getStatement(type) {
    switch (type) {
        case "payment":
            return statements[type]
        default:
            return getDefaultContent()
    }
}

function getDefaultContent() {
    return <div>
        <FormattedMessage id={'article.none'}></FormattedMessage>
    </div>
}

export function Statement() {
    // use location to find the corresponding article content
    const params = useParams();
    let statement = getStatement(params.statementType);

    return <div className={"article_area"}>
        <div className={"article_container"}>
            <div style={{
                padding: "10px 0", marginBottom: "40px", borderBottom: "1px solid #D8D9Dc",
            }}>
                <h1 className={"article_title"} style={{
                    fontSize: "44px",
                    lineHeight: "66px",
                    paddingBottom: "7px",
                    fontWeight: "500",
                    fontVariantLigatures: "no-common-ligatures",
                }}> {statement.title}</h1>
                <div style={{lineHeight: "25px", color: "#919797", fontSize: "14px"}}>
                    {statement.date}</div>
            </div>
            <div className={"article_content"} style={{
                paddingBottom: "110px", width: "100%", color: "#5F6464"
            }}>
                {statement.innerHtml}
            </div>
        </div>
    </div>

}
