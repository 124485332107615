import './App.css';
import {Header, MobileHeader} from "./components/Header/Header";
import {Footer, MobileFooter} from "./components/About/Footer";
import {useEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {IntlProvider} from "react-intl";
import {en_US} from "./configs/en_US";
import {zh_CN} from "./configs/zh_CN";

function App() {
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const storedLocale = localStorage.getItem('locale');
    const [locale, setLocale] = useState(() => {
        return storedLocale || 'en'; // 默认语言为英文
    });
    const [msg, setMsg] = useState(locale === 'zh' ? zh_CN : en_US);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        localStorage.setItem('locale', locale);
        setMsg(locale === 'zh' ? zh_CN : en_US);
    }, [locale]);

    useEffect(() => {
        // 异步更新语言设置
        async function fetchLocale() {
            try {
                const ipResponse = await fetch('https://api.ipify.org?format=json');
                const ipData = await ipResponse.json();
                const ipAddress = ipData.ip;
                const countryResponse = await fetch(`https://ipapi.co/${ipAddress}/country/`);
                const countryCode = await countryResponse.text();
                // 根据国家代码判断语言
                if (countryCode !== 'CN') {
                    setLocale('en');
                } else {
                    setLocale('zh');
                }
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        }

        if (!storedLocale || (storedLocale !== "en" && storedLocale !== "zh")) {
            fetchLocale().then(r => {
            });
        }
    });

    return (
        <IntlProvider locale={locale} messages={msg}>
            <div id={"app"}>
                {windowWidth > 770 ? <Header key={location.pathname} setLocale={setLocale}></Header> :
                    <MobileHeader key={location.pathname} setLocale={setLocale}></MobileHeader>}
                <div style={location.pathname !== "/" ? {
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    backgroundColor: "white",
                    paddingTop: "100px"
                } : {}}>
                    <Outlet></Outlet>
                </div>
                {windowWidth > 770 ? <Footer></Footer> : <MobileFooter/>}
                {windowWidth > 770 && <CircleButton></CircleButton>}
            </div>
        </IntlProvider>
    );
}


function CircleButton() {
    const handleClickBtn = () => {
        window.scrollTo(0, 0);
    }
    return <div className={"circle-btn"}>
        <button className="button" onClick={handleClickBtn}>
            <div className="button-box">
                <span className="button-elem">
                    <svg viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"/>
                    </svg>
                </span>
                <span className="button-elem button-elem2">
                    <svg viewBox="0 0 46 40">
                        <path
                            d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"/>
                    </svg>
                </span>
            </div>
        </button>
    </div>
}

export default App;
