export const en_US = {
    // links
    "About": "About",
    "About Us": "About Us",
    "Others": "Others",
    "Milestones": "Milestones",
    "Our Culture": "Our Culture",
    "Our Office": "Our Office",
    "Business": "Business",
    "Consumers": "Consumers",
    "Enterprises": "Enterprises",
    "Employees": "Employees",
    "Tencent Academy": "Tencent Academy",
    "Employee Activities": "Employee Activities",
    "Investors": "Investors",
    "Environment, Social and Governance": "Environment, Social and Governance",
    "Advertiser": "Advertiser",
    "Platform": "Platform",
    "Documents": "Documents",

    // main page
    "delete.title": "Add the function of deleting historical tasks",
    "delete.date": "2024.01.29",
    "delete.desc": "Our latest feature allows for the effortless deletion of historical tasks. Streamline your task list by removing completed or outdated entries, ensuring your focus remains on the tasks that truly matter.",

    "keyword.title": "Search Task by keywords",
    "keyword.date": "2024.01.18",
    "keyword.desc": "Elevate your efficiency with our advanced search feature that allows you to swiftly locate tasks by keywords. No more sifting through piles of data; just type in relevant terms and our smart search will present you with a curated list of tasks that match your query.",

    "exposure.title": "Increase advertising exposure",
    "exposure.date": "2024.01.03",
    "exposure.desc": "Maximize your brand's visibility with our strategic advertising solutions designed to amplify exposure. Connect with a broader audience and make a lasting impact.",

    "ai.title": "Use OpenAI to help advertisers publish tasks",
    "ai.date": "2024.03.18",
    "ai.desc": "Utilize the cutting-edge capabilities of OpenAI to streamline and enhance your advertising strategies. Our platform enables advertisers to effortlessly publish and manage tasks, reaching a wider audience with precision and effectiveness. Embrace the power of AI and transform your advertising efforts into impactful results.",

    "article.ai.title": "Easy to make your product more powerful",
    "article.ai.date": "2024.03.18",
    "article.ai.desc": "Unlock your product's full potential effortlessly. Our intuitive tools and features empower you to enhance performance and user experience with minimal effort",

    "carousel.title1": "Easy to make your product more powerful",
    "carousel.date1": "2024.03.18",
    "carousel.desc1": "Unlock your product's full potential effortlessly. Our intuitive tools and features empower you to enhance performance and user experience with minimal effort",

    "carousel.title2": "The Top 10 Technology Trends in 2024",
    "carousel.date2": "2024.01.18",
    "carousel.desc2": "Xinyou Research Institute has consulted with leading scientists and other experts to compile our annual list of top tech trends.",

    "carousel.title3": "XinYou Announces 2023 Annual and Fourth Quarter Results",
    "carousel.date3": "2024.04.18",
    "carousel.desc3": " ",

    "sms.title": "Confirmation of order completion requires SMS verification",
    "sms.date": "2023.12.21",
    "sms.desc": "For heightened transaction security, our platform's latest update introduces a mandatory SMS verification upon order completion. Customers will receive a unique code via SMS to confirm their transaction, reinforcing the protection against fraud and confirming the legitimacy of the purchase with just a few taps. Experience enhanced security with our two-step SMS verification process, ensuring each order is confirmed directly by you. Secure and straightforward, it's an added layer of trust for every transaction.",

    "What's New": "What's New",


    // ai content
    "Introduction.title": "Introduction",
    "Introduction.content": "In today’s digital age, the advertising industry continually seeks innovative technologies to enhance efficiency and effectiveness. OpenAI, with its cutting-edge artificial intelligence technologies, offers transformative solutions that streamline the task publication process for advertisers. This document explores the integration of OpenAI technologies into advertising platforms, offering a comprehensive guide on how advertisers can leverage AI to maximize their outreach and refine their marketing strategies.",
    "RoleOfAI.title": "The Role of AI in Modern Advertising",
    "RoleOfAI.content": "Artificial intelligence has become a cornerstone in digital advertising, providing tools that automate and optimize large-scale campaigns across various platforms. AI technologies analyze vast amounts of data to predict user behavior, personalize ads, and manage ad placements more efficiently than ever before. OpenAI’s advanced algorithms can process complex datasets to identify trends and insights that human analysts might miss, thus enhancing decision-making processes.",
    "StreamliningTask.title": "Streamlining Task Publication with OpenAI",
    "StreamliningTask.content": "One of the key benefits of incorporating OpenAI into advertising platforms is the automation of task publication. Advertisers often face the challenge of targeting the right audience and adjusting their strategies based on evolving market dynamics. OpenAI’s AI models can automatically generate and adjust ad content based on real-time data analysis, ensuring that advertisements are not only well-targeted but also cost-efficient.",
    "Features.title": "Features of OpenAI-Enhanced Advertising Platforms",
    "Features.automatedContentCreation": "Automated Content Creation: OpenAI’s GPT (Generative Pre-trained Transformer) models can create engaging and relevant ad copy, reducing the need for manual content creation. This automation speeds up the campaign setup process and allows advertisers to respond quickly to market changes.",
    "Features.predictiveAnalytics": "Predictive Analytics: AI-driven analytics help predict campaign performance based on historical data and current trends. This predictive power enables advertisers to allocate budgets more effectively and tweak campaigns for maximum impact.",
    "Features.audienceSegmentation": "Audience Segmentation: AI algorithms analyze user data to segment audiences based on behavior, demographics, and preferences. This segmentation helps tailor ads to specific groups, increasing the relevance and effectiveness of campaigns.",
    "Features.realTimeAdjustments": "Real-time Adjustments: AI models continuously learn from incoming data, allowing for real-time adjustments to live campaigns. This feature ensures that ads remain optimal throughout their lifecycle, adapting to user engagement and feedback.",
    "CaseStudies.title": "Case Studies",
    "CaseStudies.content": "Several case studies highlight the successful integration of OpenAI in advertising. For instance, a major retail brand used AI-driven platforms to double its reach while reducing advertising costs by 20%. Through AI, the brand could dynamically adjust its ad placements and content, resulting in higher engagement rates and increased ROI.",
    "Challenges.title": "Challenges and Considerations",
    "Challenges.content": "While AI offers numerous advantages, there are challenges to consider, such as data privacy concerns and the need for transparent AI operations. Advertisers must ensure that they use AI in compliance with data protection regulations and ethical standards.",
    "FutureProspects.title": "Future Prospects",
    "FutureProspects.content": "The future of advertising with AI looks promising, with ongoing advancements in machine learning models and AI capabilities. As AI becomes more sophisticated, it will play an even more integral role in crafting precise and impactful advertising strategies.",
    "Conclusion.title": "Conclusion",
    "Conclusion.content": "Leveraging OpenAI for advertising tasks offers substantial benefits, from automating mundane tasks to providing deep insights into campaign performance. As the industry evolves, AI technologies will become essential tools for advertisers aiming to stay competitive in a rapidly changing digital landscape.",

    // footer
    "follow.us": "Follow Us",
    "Join Us": "Join Us",
    "Contact Us": "Contact Us",
    "Legal Information": "Legal Information",
    "XinYou Careers": "XinYou Careers",
    "Campus Recruitment": "Campus Recruitment",
    "Global Recruitment": "Global Recruitment",
    "Customer Services": "Customer Services",
    "Partnership": "Partnership",
    "Procurement": "Procurement",
    "Compliance": "Compliance",
    "Media & Investors": "Media & Investors",
    "Service Agreement": "Service Agreement",
    "Privacy Policy": "Privacy Policy",
    "Intellectual Property Rights": "Intellectual Property Rights",
    "copyright": "Copyright © 2024 XinYou Inc.",
    "company-name": "XinYou Inc.",
    "website-record": "ICP 2023034876-2",


    // other
    "article.none": "Sorry! Can't find this article.",

    // error
    "error.page.title": "Oops!",
    "error.page.message": "Sorry, we are preparing our sources.",
};
