export function Society() {
    return (
        <div className="card">
            <button>
                <img src={"https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/wechat.svg"}
                     alt={"load failed"}/>
                <div className="qr-code">
                    <img src={"https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/wechat_code.jpg"}
                         alt="WeChat QR Code"/>
                </div>
            </button>
            <button>
                <img src={"https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/dc.svg"}
                     alt={"load failed"}/>
            </button>
            <button>
                <img src={"https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/fc.svg"}
                     alt={"load failed"}/>
            </button>
            <button>
                <img src={"https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/ins.svg"}
                     alt={"load failed"}/>
            </button>
            <button>
                <img src={"https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/x.svg"}
                     alt={"load failed"}/>
            </button>
        </div>

    )
}
