import "./Content.css"
import {Carousel} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

function getContent() {
    // 双重数组，行表示一个row，列表示row中的所有card，设置flexGrow来设置比例
    return [
        [
            {
                id: "ai",
                imgUrl: "https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/robots-7855433.jpg",
                title: "ai.title",
                date: "ai.date",
                desc: "ai.desc",
                style: {
                    flexGrow: "2",
                },
                imgStyle: {
                    height: "300px",
                },
            },
            {
                id: "delete",
                imgUrl: "https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/garbage-1308138.png",
                title: "delete.title",
                date: "delete.date",
                desc: "delete.desc",
                style: {
                    flexGrow: "1",
                },
                imgStyle: {
                    height: "300px",
                    objectFit: "contain"
                }
            }
        ],
        [
            {
                id: "keyword",
                imgUrl: "https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/search-7212079.jpg",
                title: "keyword.title",
                date: "keyword.date",
                desc: "keyword.desc",
                style: {
                    flexGrow: "1"
                },
                imgStyle: {
                    height: "300px",
                    objectFit: "cover",
                    objectPosition: "center"
                }
            },
            {
                id: "exposure",
                imgUrl: "https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/laptop-3781392.jpg",
                title: "exposure.title",
                date: "exposure.date",
                desc: "exposure.desc",
                style: {
                    flexGrow: "1"
                },
                imgStyle: {
                    height: "300px",
                }
            },
            {
                id: "sms",
                imgUrl: "https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/smartphone-5551318.jpg",
                title: "sms.title",
                date: "sms.date",
                desc: "sms.desc",
                style: {
                    flexGrow: "2"
                },
                imgStyle: {
                    height: "320px",
                }
            }
        ]
    ]
}

function getCaption() {
    let captions = [
        {
            id: "power",
            imgUrl: "https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/godfather-4292451.jpg",
            title: "carousel.title1",
            date: "carousel.date1",
            desc: "carousel.desc1",
            to: "/article/power"
        },
        {
            imgUrl: "https://static.www.tencent.com/img/banner/%E5%85%A8%E7%90%83%E6%95%B0%E5%AD%97%E6%A6%82%E5%BF%B5.jpg",
            title: "carousel.title2",
            date: "carousel.date2",
            desc: "carousel.desc2",
            to: "/article/tech",
            id: "tech"
        },
        {
            imgUrl: "https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/bridge.jpg",
            title: "carousel.title3",
            date: "carousel.date3",
            desc: "carousel.desc3",
            to: "/article/fund",
            id: "fund"
        },

    ]

    return captions
}

export function Content() {
    const content = getContent();
    const cards = content.map((oneRow, i) => {
        const oneRowItems = oneRow.map((v, j) => {
            return <Link className={"card card_item"} to={"/article/" + v.id} state={v} style={v.style} key={j}>
                <img className={"card-img-top"} src={v.imgUrl} alt="" style={v.imgStyle}/>
                <div className="date-container"><FormattedMessage id={v.date}/></div>
                <div className={"card-body"}>
                    <h5 className={"card-title"}>
                        <FormattedMessage id={v.title}/>
                    </h5>
                    <p className={"card-text"}><FormattedMessage id={v.desc}/></p>
                </div>
            </Link>
        })
        return <div className={"one-row"} key={i}>{oneRowItems}</div>
    });

    const carouselItems = getCaption().map((v, i) => {

        return <Carousel.Item className={"img_item"} key={i}>
            <Link to={v.to} state={v}>
                <img
                    className="d-block img_item"
                    src={v.imgUrl}
                    alt="Load Failed"
                />
                <div className="carousel-shadow ten_banner_shadow--10"></div>
                <Carousel.Caption>
                    <h3><FormattedMessage id={v.title}/></h3>
                    <p><FormattedMessage id={v.desc}/></p>
                </Carousel.Caption>
            </Link>
        </Carousel.Item>
    })

    return (
        <>
            <div id={"content"}>
                <Carousel>
                    {carouselItems}
                </Carousel>
                <div className={"cards_area"}>
                    <div className={"cards_container"}>
                        <div className={"new_area"}><FormattedMessage id="What's New" defaultMessage="What's New"/>
                        </div>
                        <div className={"cards container-xxl m-0 p-0"}>
                            {cards}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
